import { forwardRef, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { QuestionnaireGroup } from "../../QuestionnaireGroup";
import { ScrollRootToTop } from "../../../../../components/ScrollRootToTop";
import { SubModuleDisplay } from "../../submodule/SubModuleList";
import { ValidationHeader } from "../../ValidationHeader";
import type { State } from "../../../../../../state";
import type { Group, SubModule, Section } from "../../../../../../models/questionnaire";

interface Props {
	sectionId: number;
}

const isSubModule = (o: SubModule | Group): o is SubModule => "headers" in o;

export const QuestionnaireSectionContainer = forwardRef<HTMLHeadingElement, Props>(
	({ sectionId }: Props, ref) => {
		const section = useSelector<State, Section | undefined>((state) =>
			state.questionnaire.sections.find((s) => s.id === sectionId),
		);

		const groups = useSelector<State, Group[]>(
			(state) => state.questionnaire.groups.filter((g) => g.sectionId === sectionId),
			shallowEqual,
		);

		const subModules = useSelector<State, SubModule[]>(
			(state) =>
				state.questionnaire.subModules.filter(
					(sm) => sm.sectionId === sectionId && !sm.parentSubModuleId,
				),
			shallowEqual,
		);

		const subSectionsToRender: (Group | SubModule)[] = useMemo(() => {
			return [...groups, ...subModules].sort((a, b) => a.orderIndex - b.orderIndex);
		}, [groups, subModules]);

		if (!subSectionsToRender.length || !section) {
			return null;
		}

		return (
			<>
				<ScrollRootToTop observe={sectionId} />

				<ValidationHeader groups={groups} />

				{subSectionsToRender.map((s, i) => {
					if (s.hidden) {
						return null;
					}
					if (!isSubModule(s)) {
						return (
							<QuestionnaireGroup
								groupId={s.id}
								guidance={section.guidance || ""}
								guidanceIsPopup={section.guidanceIsPopup}
								key={s.id}
								mediaGuidance={section.mediaGuidance || ""}
								ref={i === 0 ? ref : undefined}
							/>
						);
					}
					return (
						<SubModuleDisplay
							hasValidationErrors={false}
							isMandatory={false}
							key={s.id}
							parentId={section.id}
							ref={i === 0 ? ref : undefined}
							subModuleId={s.id}
							validationErrors={[]}
						/>
					);
				})}
			</>
		);
	},
);

QuestionnaireSectionContainer.displayName = "QuestionnaireSectionContainer";
