import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ConfirmationModal, FormModal } from "../../../modals";
import { OptionalRegisterSelectInputList } from "../OptionalRegisterSelectInputList";
import { OptionalRegisterSelectFieldValidator } from "../../../../../validators/optionalRegisterSelectFieldValidator";
import { OptionalRegisterSelectInputPresentation } from "./OptionalRegisterSelectInput.presentation";
import type { RegisterEntity } from "../../../../../models/registerEntity";
import type { Portal } from "../../../../../models/portal";
import type { State } from "../../../../../state";
import type { OptionalSelectHolderField } from "../../../../../models/fields/OptionalSelectHolder";

interface Props {
	field: OptionalSelectHolderField;
	updateField: (fieldId: number, value: any, additionalParams?: Record<string, any>) => void;
	updateValidationStatus: (fieldId: number, value: string[]) => void;
}

export const OptionalRegisterSelectInput = ({
	field,
	updateField,
	updateValidationStatus,
}: Props) => {
	const { t } = useTranslation();
	const [showSelectModal, setShowSelectModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [checkedEntity, setCheckedEntity] = useState<RegisterEntity | undefined>(field.value);
	const [tempEntity, setTempEntity] = useState<RegisterEntity | undefined>();
	const fieldValidator = new OptionalRegisterSelectFieldValidator(field);

	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find(
			(p) =>
				state.questionnaire.questionnaire &&
				p.key === state.questionnaire.questionnaire.portalKey,
		),
	);

	const onDisplayModal = (event: React.MouseEvent) => {
		event.preventDefault();

		setShowSelectModal(true);
		setTempEntity(checkedEntity);
	};

	const onCancel = () => {
		setShowSelectModal(false);
	};

	const onOk = () => {
		setCheckedEntity(tempEntity);
		fieldValidator.isFieldValid(tempEntity);
		updateValidationStatus(field.id, fieldValidator.messages);
		updateField(field.id, tempEntity, { selectedFromRegister: true });
		setShowSelectModal(false);
	};

	const onRemove = () => {
		setCheckedEntity(undefined);
		fieldValidator.isFieldValid(undefined);
		updateField(field.id, undefined, { selectedFromRegister: true });
		updateValidationStatus(field.id, fieldValidator.messages);
		setShowRemoveModal(false);
	};

	const handleRegisterSearch = (entity: RegisterEntity) => {
		setCheckedEntity(entity);
		fieldValidator.isFieldValid(entity);
		updateValidationStatus(field.id, fieldValidator.messages);
		updateField(field.id, entity, { selectedFromRegister: true });
		setShowSelectModal(false);
	};

	const handleRemove = (event: React.MouseEvent) => {
		event.preventDefault();
		setShowRemoveModal(true);
	};

	const buttonLabel = useMemo(
		() =>
			t(checkedEntity ? "display:labelChangeEntity" : "display:labelSelectEntity", {
				entityCaption: field.name,
			}),
		[t, field.name, checkedEntity],
	);

	return (
		<>
			<OptionalRegisterSelectInputPresentation
				buttonLabel={buttonLabel}
				entity={field.value}
				onClick={onDisplayModal}
				onRemove={handleRemove}
				removeLabel={t("display:buttonDelete")}
			/>
			<FormModal
				large
				onCancel={onCancel}
				onOk={onOk}
				padded={false}
				show={showSelectModal}
				title={t("display:labelSelectEntity", {
					entityCaption: field.name,
				})}
			>
				<OptionalRegisterSelectInputList
					dataUrl={field.originalField.dataUrl}
					onEntitySearch={handleRegisterSearch}
					onSelectEntity={setTempEntity}
					portalCulture={portal ? portal.dateFormat : "Uk"}
					propertyName={field.propertyName!}
					qrScannable={field.isScannable}
					selectedEntity={tempEntity}
				/>
			</FormModal>
			<ConfirmationModal
				onCancel={() => {
					setShowRemoveModal(false);
				}}
				onConfirm={onRemove}
				show={showRemoveModal}
				text={t("display:labelClearEntitySelectField")}
			/>
		</>
	);
};
