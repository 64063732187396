import { AttachmentParentType } from "../../../../../../models/attachments/Attachment";
import { LocalSectionId } from "../../../../../../models/questionnaire";
import { FormModal } from "../../../../../components/modals";
import { SubmoduleAttachmentInput } from "../SubmoduleAttachmentInput";
import { SubModuleDisplay } from "../SubModuleList";
import { SubModuleField } from "../subModuleField";
import { ValidationHeader } from "../../ValidationHeader";
import styles from "./styles.module.scss";
import type { FieldType, SubModule } from "../../../../../../models/questionnaire";
import type { Field } from "../../../../../../models/fields/Field";

interface Props {
	allowActions?: boolean;
	allowAttachments?: boolean;
	cancel: () => void;
	displayValidation: boolean;
	fields: Field[];
	isAuthenticated?: boolean;
	isOpen: boolean;
	isEdit: boolean;
	parentId: string | number;
	questionnaireId: string;
	recordGuid: string;
	saveRecord: () => void;
	subModuleErrors?: string[];
	subModuleId: LocalSectionId;
	subModules: SubModule[];
	storeFieldValue: (
		fieldId: number,
		value: any,
		type: FieldType,
		additionalParams?: { selectedFromRegister?: boolean },
	) => void;
	title: string;
	updateFieldValidation: (fieldId: number, errors: string[]) => void;
}

export const SubModuleDialogPresentation = ({
	allowActions,
	allowAttachments,
	cancel,
	displayValidation,
	fields,
	isAuthenticated,
	isEdit,
	isOpen,
	parentId,
	questionnaireId,
	recordGuid,
	saveRecord,
	storeFieldValue,
	subModuleErrors,
	subModuleId,
	subModules,
	title,
	updateFieldValidation,
}: Props) => {
	return (
		<FormModal onCancel={cancel} onOk={saveRecord} show={isOpen} title={title}>
			<div className={styles.subModuleFields}>
				<div className={displayValidation ? styles.validationErrors : ""}>
					{displayValidation && (
						<ValidationHeader groups={undefined} subModuleErrors={subModuleErrors} />
					)}
				</div>

				{fields &&
					fields.length > 0 &&
					fields.map(
						(f: Field, i: number) =>
							!f.hidden &&
							(!f.authenticatedOnly || (f.authenticatedOnly && isAuthenticated)) && (
								<SubModuleField
									fieldGuid={f.guid}
									fieldValue={f.value}
									isEdit={isEdit}
									key={i}
									updateFieldValidation={updateFieldValidation}
									updateSubModuleField={storeFieldValue}
									validationErrors={f.validationMessage}
								/>
							),
					)}
				{subModules.map((subModule) => (
					<SubModuleDisplay
						key={subModule.id}
						hasValidationErrors={false}
						isIQ={false}
						isInSubmodule
						isMandatory={false}
						parentGuid={recordGuid}
						parentId={parentId}
						subModuleId={subModule.id}
						validationErrors={[]}
					/>
				))}

				{allowActions && (
					<div className="she-components-control">
						<SubModuleDisplay
							hasValidationErrors={false}
							isIQ={false}
							isInSubmodule
							isMandatory={false}
							parentId={parentId}
							subModuleId={LocalSectionId.Actions}
							validationErrors={[]}
						/>
					</div>
				)}

				{allowAttachments && typeof parentId === "string" && (
					<SubmoduleAttachmentInput
						parentGuid={parentId}
						parentType={AttachmentParentType.Submodule}
						questionnaireId={questionnaireId}
						subModuleId={subModuleId}
					/>
				)}
			</div>
		</FormModal>
	);
};
