import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldType, LocalFieldIds, SelectFieldType } from "../../../../../../models/questionnaire";
import { TextInput } from "../../../../../components/input/TextInput";
import { NumberInput } from "../../../../../components/input/NumberInput";
import { BooleanInput } from "../../../../../components/input/BooleanInput";
import { DateInput } from "../../../../../components/input/DateInput";
import { TimeInput } from "../../../../../components/input/TimeInput";
import { DateTimeInput } from "../../../../../components/input/DateTimeInput";
import { MultiSelectInput } from "../../../../../components/input/MultiSelectInput";
import { SingleSelectInput } from "../../../../../components/input/SingleSelectInput";
import { MatrixInput } from "../../../../../components/input/MatrixInput";
import { SignatureInput } from "../../../../../components/input/SignatureInput";
import { OrgUnitSelector } from "../../../../../components/input/OrgUnitSelector";
import { Base64Image } from "../../../../../components/generic/Base64Image";
import { TextInputSelectable } from "../../../../../components/input/TextInputSelectable";
import { PPEInput } from "../../../../../components/input/PPEInput";
import { EmailInput } from "../../../../../components/input/EmailInput";
import { EntitySelectInput } from "../../../../../components/input/EntitySelectInput";
import { WithDefaultValueEntitySelect } from "../../../../../components/input/WithDefaultValueEntitySelect";
import { PortalGuard } from "../../../../../components/PortalGuard";
import { ConditionalWrapper } from "../../../../../components/ConditionalWrapper";
import { OptionalRegisterSelect } from "../../../../../components/OptionalRegisterSelect";
import { SubModuleFieldPresentation } from "./subModuleField.presentation";
import type { SelectableTextField } from "../../../../../../models/fields/SelectableTextField";
import type { State } from "../../../../../../state";
import type { EnumSelectField } from "../../../../../../models/fields/EnumSelectField";
import type { MultiSelectField } from "../../../../../../models/fields/MultiSelectField";
import type { SingleSelectField } from "../../../../../../models/fields/SingleSelectField";
import type { Field } from "../../../../../../models/fields/Field";

interface Props {
	fieldGuid: string;
	fieldValue: any;
	validationErrors: string[];
	isEdit: boolean;
	updateSubModuleField: (
		fieldId: number,
		value: any,
		type: FieldType,
		additionalParams?: { selectedFromRegister?: boolean },
	) => void;
	updateFieldValidation: (fieldId: number, errors: string[]) => void;
}

export const SubModuleFieldContainer = ({
	fieldGuid,
	fieldValue,
	isEdit,
	updateFieldValidation,
	updateSubModuleField,
	validationErrors,
}: Props) => {
	const question = useSelector<State, Field>(
		(state) => state.questionnaire.fields.find((f) => f.guid === fieldGuid)!,
	);
	const startDate = useSelector<State, Date>((state) =>
		state.questionnaire.status && state.questionnaire.status.dateCreated
			? state.questionnaire.status.dateCreated
			: new Date(),
	);
	const [field, setField] = useState(question);
	const [valErrors, setValErrors] = useState(validationErrors);
	const [updated, setUpdated] = useState(false);
	const { t } = useTranslation();

	const updateFieldValue = (
		fieldId: number,
		value: any,
		additionalParams?: { selectedFromRegister?: boolean },
	) => {
		setValErrors([]);
		// TODO: what are we trying to achieve here, we are still mutating the original reference with this. tut tut
		const f = field;
		f.value = value;
		setField(f);
		updateFieldValidation(fieldId, []);
		updateSubModuleField(fieldId, value, field.type, additionalParams);
	};

	useEffect(() => {
		setValErrors(validationErrors);
	}, [validationErrors]);

	// added to deal with cascading picklists in sub modules
	useEffect(() => {
		if (field.type === FieldType.Select && field.selectType === SelectFieldType.SingleSelect) {
			const toUpdate = field as SingleSelectField;
			toUpdate.data = (question as SingleSelectField).data;

			setField(toUpdate);
			setUpdated(!updated);

			if (toUpdate.value && !toUpdate.data.has(toUpdate.value)) {
				updateFieldValue(toUpdate.id, undefined);
			}
		} else if (field.type === FieldType.SelectableText) {
			const toUpdate = field;
			toUpdate.data = (question as SelectableTextField).data;

			setField(toUpdate);
			setUpdated(!updated);
		}
	}, [question]);

	useEffect(() => {
		const f = field;
		f.value = fieldValue;
		setField(f);
		setUpdated(!updated);
	}, [fieldValue]);

	if (!field) {
		return null;
	}

	const updateFieldValidationStatus = (fieldId: number, value: string[]) => {
		updateFieldValidation(fieldId, value);
	};

	if (!field) {
		return null;
	}

	return (
		<ConditionalWrapper condition={!!field.authenticatedOnly} parent={PortalGuard}>
			<div
				className={`she-components-control ${
					valErrors && valErrors.length > 0 ? "she-components-validation-border-left" : ""
				}`}
			>
				{field.type !== FieldType.PPE && (
					<SubModuleFieldPresentation
						guid={field.guid}
						guidance={field.guidance}
						guidanceIsPopup={field.guidanceIsPopup}
						mediaGuidance={field.mediaGuidance}
						required={field.isMandatory}
						showGuidance={field.showGuidance}
						title={field.name}
						validationErrors={valErrors}
					/>
				)}
				{field.type === FieldType.Text && (
					<TextInput
						textField={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.SelectableText && (
					<TextInputSelectable
						field={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Number && (
					<NumberInput
						numberField={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Boolean && (
					<BooleanInput
						booleanField={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Select &&
					field.selectType === SelectFieldType.SingleSelect && (
						<SingleSelectInput
							field={field as SingleSelectField}
							updateField={updateFieldValue}
							updateValidationStatus={updateFieldValidationStatus}
						/>
					)}
				{field.type === FieldType.Select &&
					field.selectType === SelectFieldType.EnumSingleSelect && (
						<SingleSelectInput
							field={field as EnumSelectField}
							updateField={updateFieldValue}
							updateValidationStatus={updateFieldValidationStatus}
						/>
					)}
				{field.type === FieldType.Select &&
					field.selectType === SelectFieldType.MultiSelect && (
						<MultiSelectInput
							multiSelectField={field as MultiSelectField}
							updateField={updateFieldValue}
							updateValidationStatus={updateFieldValidationStatus}
						/>
					)}
				{field.type === FieldType.Date && (
					<DateInput
						field={field}
						startDate={startDate}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Time && (
					<TimeInput
						field={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.DateTime && (
					<DateTimeInput
						field={field}
						startDate={startDate}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Matrix && (
					<MatrixInput
						matrixField={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.OrgUnit && (
					<OrgUnitSelector
						field={field}
						onSelect={(id) => updateFieldValue(LocalFieldIds.OrgUnit, id)}
						selectedId={field.value}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Signature &&
					(isEdit ? (
						<div>
							<Base64Image
								altString={t("display:labelSignature")}
								className="she-components-signature-display"
								value={field.value}
							/>
						</div>
					) : (
						<SignatureInput
							signatureField={field}
							updateField={updateFieldValue}
							updateValidationStatus={updateFieldValidationStatus}
						/>
					))}
				{field.type === FieldType.PPE && (
					<PPEInput
						field={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.Email && (
					<EmailInput
						emailField={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.EntitySelect && (
					<EntitySelectInput
						field={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.EntitySelectWithDefault && (
					<WithDefaultValueEntitySelect
						field={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
				{field.type === FieldType.OptionalSelectHolder && (
					<OptionalRegisterSelect
						field={field}
						updateField={updateFieldValue}
						updateValidationStatus={updateFieldValidationStatus}
					/>
				)}
			</div>
		</ConditionalWrapper>
	);
};
