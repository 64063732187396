import { FieldType, type QuestionnaireTemplate } from "../../models/questionnaire";

type UpgradeFunction = (source: QuestionnaireTemplate) => QuestionnaireTemplate;

const upgrades: Record<number, UpgradeFunction> = {
	// Change indirect field type from Indirect to OptionalSelectHolder to fix value type.
	// @ts-expect-error -- We are changing the type of the field as indirect field's value was incrorrectly used to store data different than string.
	1: (source: QuestionnaireTemplate) => {
		return {
			...source,
			fields: source.fields.map((field) => {
				if (
					field.type === FieldType.Indirect &&
					field.originalField.type === FieldType.OptionalSelect
				) {
					return {
						...field,
						type: FieldType.OptionalSelectHolder,
					};
				}
				return field;
			}),
		};
	},
	2: (source: QuestionnaireTemplate) => {
		return {
			...source,
			groups: source.groups.map((group) => ({
				...group,
				hidden: false,
			})),
			subModules: source.subModules.map((subModule) => ({
				...subModule,
				hidden: false,
			})),
		};
	},
};

const LATEST_VERSION = Math.max(...Object.keys(upgrades).map(Number));

// TODO: Make it generic upgrade function
export const upgradeQuestionnaire = (source: QuestionnaireTemplate): QuestionnaireTemplate => {
	let currentVersion = source.localVersion || 0;

	if (currentVersion > LATEST_VERSION) {
		throw new Error(
			`Cannot upgrade questionnaire from version ${currentVersion}. Questionnaire is ahead of the latest version ${LATEST_VERSION}.`,
		);
	}

	if (currentVersion === LATEST_VERSION) {
		return source;
	}

	let upgraded = { ...source };

	while (currentVersion < LATEST_VERSION) {
		// Find next upgrade function
		const nextVersion = Math.min(
			...Object.keys(upgrades)
				.map(Number)
				.filter((v) => v > currentVersion),
		);
		upgraded = upgrades[nextVersion](upgraded);
		currentVersion = nextVersion;
	}

	upgraded.localVersion = currentVersion;
	return upgraded;
};

export const isQuestionnaireUpgradeAvailable = (source: QuestionnaireTemplate): boolean => {
	return source.localVersion !== LATEST_VERSION;
};

export const getLatestQuestionnaireVersion = (): number => {
	return LATEST_VERSION;
};
