export enum UpdatableFieldProperty {
	AuditScoreComment = "scoreComment",
	AuditScoreValue = "scoreField.value",
	BehaviouralSafeValue = "safeField.value",
	BehaviouralSafeComment = "safeComment",
	BehaviouralUnSafeValue = "unSafeField.value",
	BehaviouralUnSafeComment = "unSafeComment",
	IQSignatureName = "fullname.value",
	IQSignatureDescription = "description.value",
	IQSignatureSignature = "signature.value",
	GeoPositionLong = "long.value",
	GeoPositionLat = "lat.value",
	GeoPositionDescription = "description.value",
	DateMin = "min",
	DateMax = "max",
	actionValidationMessage = "actionValidationMessage",
	attachmentValidationMessage = "attachmentValidationMessage",
	hidden = "hidden",
}
