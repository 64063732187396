import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuth } from "oidc-react";
import { FieldType, SelectFieldType, LocalSectionId } from "../../../../../../models/questionnaire";
import { GetReadOnlyDate } from "../../../../../../helpers/DateTimeInputHelper";
import { getDisplayValue } from "../../../../../../helpers/NumberInputHelper";
import { SubModuleRecordPresentation } from "./SubModuleRecord.Presentation";
import type { State } from "../../../../../../state";
import type { Field } from "../../../../../../models/fields/Field";
import type { SelectField } from "../../../../../../models/fields/SelectField";
import type { EnumSelectField } from "../../../../../../models/fields/EnumSelectField";
import type { SingleSelectField } from "../../../../../../models/fields/SingleSelectField";
import type { Dictionary } from "../../../../../../models/dictionary/Dictionary";
import type { Matrix } from "../../../../../../models/matrix/matrix";
import type { MatrixField } from "../../../../../../models/fields/MatrixField";
import type { EntityPropertyInfo } from "../../../../../../models/entity";
import type { SubModule, SubModuleRecord } from "../../../../../../models/questionnaire";

interface Props {
	record: SubModuleRecord;
	headers: { id: number; value: string }[];
	removeRecord: (id: string) => void;
	editRecord: (record: SubModuleRecord) => void;
	allowActions?: boolean;
	allowAttachments?: boolean;
}

export interface SubmoduleRecordDisplayItem {
	header: string;
	value: string;
	colour: string;
	type: FieldType;
	orderIndex: number;
}

export const SubModuleRecordContainer = ({
	editRecord,
	headers,
	record,
	removeRecord,
	allowActions,
	allowAttachments,
}: Props) => {
	const { t } = useTranslation();
	const fields = useSelector<State, Field[] | undefined>((state) =>
		state.questionnaire.fields.filter(
			(f) => f.subModuleId && f.subModuleId === record.subModuleId,
		),
	);
	const matrices = useSelector<State, Matrix[] | undefined>((state) =>
		state.questionnaire.matrixes.filter(
			(m) =>
				fields &&
				fields
					.map((f) => {
						if (f.type === FieldType.Matrix) {
							return f.matrixId;
						}
						return null;
					})
					.includes(m.id),
		),
	);

	const actions = useSelector<State, number>((state) => {
		return state.questionnaire.subModuleRecords.filter(
			(s) => s.parentId === record.localId && s.subModuleId === LocalSectionId.Actions,
		).length;
	});

	const attachments = useSelector<State, number>((state) => {
		return state.recordAttachments.attachments.filter((a) => a.parentGuid === record.localId)
			.length;
	});

	const dictionaries = useSelector<State, Dictionary[] | undefined>(
		(state) => state.questionnaire.dictionaries,
	);

	const subModules = useSelector<State, SubModule[] | undefined>((state) =>
		state.questionnaire.subModules.filter(
			(submodule) => submodule.parentSubModuleId === record.subModuleId,
		),
	);

	const subModuleRecords = useSelector<State, SubModuleRecord[] | undefined>((state) =>
		state.questionnaire.subModuleRecords.filter(
			(submoduleRecord) => submoduleRecord.parentGuid === record.localId,
		),
	);

	const submModuleRecordsCount = subModules?.reduce<SubmoduleRecordDisplayItem[]>(
		(acc, subModule) => {
			const count = subModuleRecords?.filter(
				(record) => record.subModuleId === subModule.id,
			).length;
			acc.push({
				header: subModule.name,
				value: count?.toString() || "0",
				colour: "",
				type: FieldType.Text,
				orderIndex: 0,
			});
			return acc;
		},
		[],
	);

	const { userData } = useAuth();

	const fieldsToDisplay: SubmoduleRecordDisplayItem[] = headers.reduce<
		SubmoduleRecordDisplayItem[]
	>((acc, h) => {
		const header = h.value;
		const val = record.values.find((r) => r.id === h.id);
		const hidden = fields && fields.filter((f) => f.hidden && f.id === h.id);
		let colour = "";
		const recordField = fields && fields.find((f) => f.id === h.id);
		const fieldOrderIndex: number = recordField ? recordField.orderIndex : 0;

		if (!val && !recordField) {
			return acc;
		}

		if (val && val.value) {
			let displayVal = val.value;
			if (
				val.type === FieldType.Date ||
				val.type === FieldType.Time ||
				val.type === FieldType.DateTime
			) {
				displayVal = GetReadOnlyDate(val) || "";
			} else if (val.type === FieldType.Number) {
				displayVal = getDisplayValue(val.value, true, false) || "";
			} else if (val.type === FieldType.SelectableText || val.type === FieldType.Text) {
				displayVal = val.value.replace(/^\s*[\r\n]/gm, "");
			} else if (val.type === FieldType.Boolean) {
				displayVal = val.value ? t("global:yes") : t("global:no");
			} else if (val.type === FieldType.Matrix) {
				const field = fields && (fields.find((f) => f.id === val.id) as MatrixField);
				if (field) {
					const matrix =
						matrices && matrices.find((m) => field && m.id === field.matrixId);
					if (matrix) {
						const matrixValue = matrix.factors.find(
							(mf) => mf.id === (val.value as unknown as number),
						);

						if (matrixValue) {
							let text = matrixValue.value.toString();
							switch (matrix.captionType) {
								case 1:
									text = `${matrixValue.value}-${matrixValue.caption}`;
									break;
								case 2:
									text = matrixValue.caption;
									break;
								case 3:
									text = `${matrixValue.value}`;
									break;
							}
							displayVal = text;
							colour = matrixValue.colour;
						}
					}
				}
			} else if (val.type === FieldType.Select && !Number.isNaN(parseInt(val.value))) {
				const field = fields && fields.find((f) => f.id === val.id);
				if (field) {
					const selectField = field as SelectField<number>;
					if (selectField.selectType === SelectFieldType.EnumSingleSelect) {
						const enumVal =
							(field as EnumSelectField).enumData &&
							(field as EnumSelectField).enumData.find(
								(d) => d.value === parseInt(val.value),
							);
						if (enumVal) {
							displayVal = enumVal.enumText;
						}
					} else if (selectField.selectType === SelectFieldType.SingleSelect) {
						const selectField = field as SingleSelectField;

						if (dictionaries) {
							const dictionary = dictionaries.find(
								(d) => d.key === selectField.dictionaryKey,
							);

							if (dictionary) {
								const item = dictionary.dictionaryItems.find(
									(x) => x.id === val.value,
								);
								displayVal = item ? item.text : "";
							}
						}
					}
				}
			} else if (
				val.type === FieldType.EntitySelectWithDefault ||
				val.type === FieldType.EntitySelect
			) {
				if (val && val.value && val.value.info) {
					displayVal = val.value.info.reduce(
						(acc: string, current: EntityPropertyInfo, index: number) => {
							if (current.detail) {
								return `${acc}${index > 0 ? ", " : ""}${current.detail}`;
							}
							return acc;
						},
						"",
					);
				} else if (val && val.value === "UseDefaultValue") {
					const field = fields && fields.find((f) => f.id === val.id);
					displayVal = t("display:entitySelectWithDefault.defaultValue", {
						propertyName: field ? field.name : "",
					});
				}
			} else if (val.type === FieldType.OptionalSelectHolder) {
				if (val.value?.selectedFromRegister === true) {
					displayVal = val.value.info.reduce(
						(acc: string, current: any, index: number) => {
							if (current.value) {
								return `${acc}${index > 0 ? ", " : ""}${current.value}`;
							}
							return acc;
						},
						"",
					);
				} else {
					const manualEntry = record.values.find(
						(manualValue) => manualValue.id === val.id && manualValue !== val,
					);
					displayVal = manualEntry ? manualEntry.value : "";
				}
			}

			let recordItem: SubmoduleRecordDisplayItem;

			hidden && hidden.length === 0
				? (recordItem = {
						header,
						value: displayVal,
						colour,
						type: val.type,
						orderIndex: fieldOrderIndex,
				  })
				: (recordItem = {
						header: "",
						value: "",
						colour: "",
						type: FieldType.Text,
						orderIndex: 0,
				  });
			acc.push(recordItem);
			return acc;
		}
		acc.push({
			header,
			value: "",
			colour: "",
			type: FieldType.Text,
			orderIndex: fieldOrderIndex,
		});
		return acc;
	}, []);

	const handleRemoveRecord = () => {
		removeRecord(record.localId);
	};

	const handleEditRecord = () => {
		editRecord(record);
	};

	const onKeyPress = (key: string) => {
		if (key === "Enter" || key === " ") {
			handleEditRecord();
		}
	};

	return (
		<SubModuleRecordPresentation
			actions={allowActions ? actions : null}
			attachments={allowAttachments ? attachments : null}
			editRecord={handleEditRecord}
			fieldsToDisplay={[
				...fieldsToDisplay.sort((a, b) => a.orderIndex - b.orderIndex),
				...(submModuleRecordsCount || []),
			]}
			isAuthenticated={!!userData}
			key={record.localId}
			onKeyPress={onKeyPress}
			removeRecord={handleRemoveRecord}
		/>
	);
};
