import { useState, useEffect, useMemo, useCallback } from "react";
import { useAuth } from "oidc-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { clearOrphanedAttachmentsFromState } from "../../../../../../state/components/attachment";
import { ConfirmationModal } from "../../../../../components/modals";
import { SubModuleFieldsValidator } from "../../../../../../validators/subModuleFieldsValidator";
import {
	updateField,
	updateValidationStatus,
} from "../../../../../../state/components/questionnaire";
import { FieldType } from "../../../../../../models/questionnaire";
import { QuestionnaireActionTypes } from "../../../../../../state/components/questionnaire/actions/types";
import { useAppDispatch } from "../../../../../../state/hooks/useAppDispatch";
import { SubModuleDialogPresentation } from "./SubModuleDialog.presentation";
import type { Field } from "../../../../../../models/fields/Field";
import type { SubModule, SubModuleRecord } from "../../../../../../models/questionnaire";
import type { Portal } from "../../../../../../models/portal";
import type { State } from "../../../../../../state";
import type { OptionalSelectHolderField } from "../../../../../../models/fields/OptionalSelectHolder";

interface Props {
	cancel: () => void;
	isInSubmodule?: boolean;
	isOpen: boolean;
	parentId: number | string;
	saveRecord: (subModuleRecord: SubModuleRecord) => void;
	subModuleId: number;
	subModuleRecordId?: string;
}

export const SubModuleDialogContainer = ({
	cancel,
	isInSubmodule,
	isOpen,
	parentId,
	saveRecord,
	subModuleId,
	subModuleRecordId,
}: Props) => {
	const { t } = useTranslation();
	const [localId, setLocalId] = useState<string>(uuid());

	const { userData } = useAuth();

	const dispatch = useAppDispatch();
	const templateId = useSelector<State, string>((state) => state.questionnaire.questionnaire!.id);

	const subModule = useSelector<State, SubModule | undefined>((state) =>
		state.questionnaire.subModules.find((x) => x.id === subModuleId),
	);
	const toEdit = useSelector<State, SubModuleRecord | undefined>((state) =>
		state.questionnaire.subModuleRecords.find((x) => x.localId === subModuleRecordId),
	);

	const [showErrors, setShowErrors] = useState(false);

	const fields = useSelector<State, Field[]>((state) =>
		state.questionnaire.fields
			.filter((x) => x.subModuleId === subModuleId)
			.sort((a, b) => a.orderIndex - b.orderIndex),
	);

	const errors = useMemo(() => {
		return fields
			.filter(
				(field) =>
					(field.validationMessage.length > 0 ||
						field.type === FieldType.OptionalSelectHolder) &&
					!field.hidden,
			)
			.reduce<string[]>((acc, field) => {
				field.validationMessage.forEach((message) => {
					acc.push(`${field.name}: ${message}`);
				});
				if (
					field.type === FieldType.OptionalSelectHolder &&
					field.originalField.selectedFromRegister === false
				) {
					const relatedFields = field.relatedFields;
					if (relatedFields && relatedFields.length > 0) {
						relatedFields.forEach((relatedField) => {
							if (relatedField.validationMessage.length > 0 && !relatedField.hidden) {
								relatedField.validationMessage.forEach((message) => {
									acc.push(`${relatedField.name}: ${message}`);
								});
							}
						});
					}
				}
				return acc;
			}, []);
	}, [fields]);

	const portal = useSelector<State, Portal | undefined>((state) =>
		state.portal.portals.find((p) =>
			state.questionnaire.questionnaire
				? p.key === state.questionnaire.questionnaire.portalKey
				: undefined,
		),
	);

	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
	const [isFormDirty, setIsFormDirty] = useState(false);

	const portalCulture = portal ? portal.dateFormat : undefined;

	const subModuleFieldValidator = useMemo(
		() => new SubModuleFieldsValidator(portalCulture),
		[portalCulture],
	);

	const storeFieldValue = useCallback(
		(
			fieldId: number,
			value: any,
			type: FieldType,
			additionalParams?: {
				isFieldChange?: boolean;
				selectedFromRegister?: boolean;
			},
		) => {
			if (!subModule) {
				return;
			}

			dispatch(
				updateField(
					fieldId,
					value,
					true,
					additionalParams && "selectedFromRegister" in additionalParams
						? { selectedFromRegister: additionalParams.selectedFromRegister }
						: undefined,
				),
			);

			if (additionalParams?.isFieldChange && !isFormDirty) {
				setIsFormDirty(true);
			}
		},
		[dispatch, isFormDirty, subModule],
	);

	// Restore fields to default values when dialog is opened
	const clearFields = useCallback(() => {
		dispatch({
			type: QuestionnaireActionTypes.ResetFields,
			fieldIds: fields.map((field) => field.id),
		});
	}, [fields, dispatch]);

	// Populate fields with default values or values from record to edit when dialog is opened
	useEffect(() => {
		setShowErrors(false);
		setLocalId(toEdit?.localId ?? uuid());
		if (isOpen) {
			clearFields();
			if (subModuleRecordId && toEdit) {
				toEdit.values.forEach((recordValue) => {
					if (recordValue.type === FieldType.OptionalSelectHolder) {
						dispatch(
							updateField(recordValue.id, recordValue.value, true, {
								selectedFromRegister: recordValue.value.selectedFromRegister,
							}),
						);
						return;
					}
					storeFieldValue(recordValue.id, recordValue.value, recordValue.type);
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once opening/closing dialog
	}, [isOpen]);

	const subModules = useSelector<State, SubModule[]>((state) =>
		state.questionnaire.subModules.filter((sm) => sm.parentSubModuleId === subModuleId),
	);

	if (!subModule) {
		return null;
	}

	const saveButtonClicked = () => {
		if (isSubModuleRecordValid()) {
			const recordToSave: SubModuleRecord = {
				localId,
				parentId,
				subModuleId,
				values: fields.map((field) => ({
					id: field.id,
					type: field.type,
					value: field.value,
				})),
			};

			fields
				.filter((field) => field.type === FieldType.OptionalSelectHolder)
				.forEach((field) => {
					// Move selectedFromRegister to value for storing
					const fieldValue = recordToSave.values.find((record) => record.id === field.id);
					if (fieldValue) {
						fieldValue.value = {
							...fieldValue.value,
							selectedFromRegister: (field as OptionalSelectHolderField).originalField
								.selectedFromRegister,
						};
					}
					// Add related fields values to record
					field.relatedFields?.forEach((relatedField) => {
						if (relatedField.value) {
							recordToSave.values.push({
								id: relatedField.id,
								type: relatedField.type,
								value: relatedField.value,
							});
						}
					});
				});

			setIsFormDirty(false);
			clearFields();
			saveRecord(recordToSave);
		} else {
			displayErrorHeader();
		}
	};

	const displayErrorHeader = () => {
		setShowErrors(true);
	};

	const doCancel = () => {
		setIsFormDirty(false);
		clearFields();
		dispatch(clearOrphanedAttachmentsFromState());
		cancel();
	};

	const cancelButtonClicked = () => {
		if (isFormDirty) {
			setIsConfirmationModalOpen(true);
		} else {
			doCancel();
		}
	};

	const isSubModuleRecordValid = (): boolean => {
		let isValid = true;

		fields.forEach((field) => {
			const valid = validateField(field, field.value);

			if (valid.length > 0 && !field.hidden) {
				isValid = false;
			}

			dispatch(updateValidationStatus(field.id, valid));

			if (
				field.type === FieldType.OptionalSelectHolder &&
				field.originalField.selectedFromRegister === false
			) {
				const relatedFields = field.relatedFields;
				if (relatedFields && relatedFields.length > 0) {
					relatedFields.forEach((relatedField) => {
						const relatedFieldValid = validateField(relatedField, relatedField.value);
						dispatch(updateValidationStatus(relatedField.id, relatedFieldValid));
						if (relatedFieldValid.length > 0) {
							isValid = false;
						}
					});
				}
			}
		});

		return isValid;
	};

	const validateField = (field: Field, value: any): string[] => {
		return subModuleFieldValidator.isFieldValid(field, value);
	};

	const handleStoreFieldValue = (
		id: number,
		value: any,
		type: FieldType,
		additionalParams?: Record<string, any>,
	) => {
		storeFieldValue(id, value, type, { ...additionalParams, isFieldChange: true });
	};

	const handleUpdateFieldValidationStatus = (fieldId: number, errors: string[]) => {
		dispatch(updateValidationStatus(fieldId, errors));
	};

	return (
		<>
			<SubModuleDialogPresentation
				allowActions={subModule.allowActions}
				allowAttachments={subModule.allowAttachments}
				cancel={cancelButtonClicked}
				displayValidation={showErrors}
				fields={fields}
				isAuthenticated={!!userData}
				isEdit={toEdit !== undefined}
				isOpen={isOpen}
				parentId={localId}
				questionnaireId={templateId}
				recordGuid={localId}
				saveRecord={saveButtonClicked}
				storeFieldValue={handleStoreFieldValue}
				subModuleErrors={errors}
				subModuleId={subModuleId}
				title={isInSubmodule ? t("display:labelAction") : subModule.name}
				updateFieldValidation={handleUpdateFieldValidationStatus}
				subModules={subModules}
			/>

			<ConfirmationModal
				onCancel={() => setIsConfirmationModalOpen(false)}
				onConfirm={doCancel}
				show={isConfirmationModalOpen}
				text={t("display:labelAreYouSure")}
			/>
		</>
	);
};
