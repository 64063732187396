import { t } from "i18next";
import type { RegisterEntity } from "../../models/registerEntity";
import type { Field } from "../../models/fields/Field";
import type { OptionalSelectField } from "../../models/fields/OptionalSelectField";
import type { OptionalSelectHolderField } from "../../models/fields/OptionalSelectHolder";

export class OptionalRegisterSelectFieldValidator {
	messages: string[] = [];
	originalField: OptionalSelectField;

	constructor(public entityField: Field) {
		this.originalField = (entityField as OptionalSelectHolderField).originalField;
	}

	isFieldValid = (value?: RegisterEntity): boolean => {
		if (this.originalField.selectedFromRegister === false) {
			return true;
		}

		let result = true;

		if (
			(this.originalField.selectedFromRegister ||
				this.originalField.selectedFromRegister === null) &&
			this.entityField.isMandatory &&
			value === undefined
		) {
			this.messages.push(
				t("validation:optionalSelect.chooseSelection", {
					propertyName: this.entityField.registerModuleName,
				}),
			);
			result = false;
		}

		if (
			(this.originalField.selectedFromRegister ||
				this.originalField.selectedFromRegister === null) &&
			this.entityField.isMandatory &&
			value === undefined
		) {
			this.messages.push(t("validation:mandatoryFieldMessage"));
			result = false;
		}

		return result;
	};

	shouldValidateRelatedFields = () => this.originalField.selectedFromRegister === false;
}
