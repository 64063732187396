import { localData } from "../indexeddb/BainDatabase";
import {
	convertBlobFilesToArrayBufferType,
	transformPortalForStorage,
	transformPortalForView,
} from "../../../../helpers/LocalDataServiceHelper";
import {
	QuestionnaireProgressState,
	isEncryptedQuestionnaireTemplate,
	QuestionnaireEncryptionVersion,
} from "../../../../models/questionnaire";
import { CommonActionTypes } from "../../../../state/components/common/actions/types";
import { store } from "../../../../state";
import { decrypt, decryptArrayBuffer, encrypt } from "../../../../helpers/EncryptionHelper";
import { getEncryptionKey } from "../../../../helpers/AuthenticationHelpers";
import { logException } from "../../../log";
import { PortalAuthenticationMode, type Portal } from "../../../../models/portal";
import {
	isQuestionnaireUpgradeAvailable,
	upgradeQuestionnaire,
} from "../../../utilities/upgradeQuestionnare";
import type { LocalDataService } from "../../interface";
import type { StoredAttachment, Attachment } from "../../../../models/attachments/Attachment";
import type { OrgUnit } from "../../../../models/orgUnit";
import type {
	QuestionnaireTemplate,
	EncryptedQuestionnaireTemplate,
	StoredQuestionnaireTemplate,
} from "../../../../models/questionnaire";
import type { AuthToken } from "../../../../models/authentication/authToken";
import type { CommonErrorAction } from "../../../../state/components/common/actions/definitions";
import type { User } from "oidc-client-ts";

// Indexeddb access is handled by the Dexie.js library
// More info can be found here: https://dexie.org/docs/API-Reference#quick-reference
export class DefaultLocalDataService implements LocalDataService {
	async getAuthTokenByCustomerKey(customerKey: string): Promise<AuthToken | undefined> {
		const tokens = await localData.auth.where({ CustomerKey: customerKey }).toArray();
		return tokens.length > 0 ? tokens[0] : undefined;
	}

	async getQuestionnaireForSubmission(
		portalKey: string,
		user: User | null,
	): Promise<QuestionnaireTemplate | undefined> {
		let questionnaire: StoredQuestionnaireTemplate | undefined;
		if (user) {
			questionnaire = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.first();
		} else {
			questionnaire = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.first();
		}
		return (
			questionnaire && this.questionnaireTemplateFromStoredQuestionnnaire(questionnaire, user)
		);
	}

	async hasFailedUploads(portalKey: string, user: User | null): Promise<boolean> {
		let failures: number;
		if (user) {
			failures = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.count();
		} else {
			failures = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.count();
		}
		return failures > 0;
	}

	async saveAuthenticationToken(token: AuthToken): Promise<string | void> {
		return localData.auth.put(token).catch((e) => {
			this.handleError(e, false);
		});
	}

	async getFailedUploads(portalKey: string, user: User | null): Promise<QuestionnaireTemplate[]> {
		let records: StoredQuestionnaireTemplate[];
		if (user) {
			records = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.toArray();
		} else {
			records = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.toArray();
		}
		return this.questionnaireTemplatesFromStoredQuestionnnaires(records, user);
	}

	async getQueuedQuestionnaires(
		portalKey: string,
		user: User | null,
	): Promise<QuestionnaireTemplate[]> {
		let records: StoredQuestionnaireTemplate[];
		if (user) {
			records = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Uploading, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Uploading, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.toArray();
		} else {
			records = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Uploading, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.toArray();
		}
		return this.questionnaireTemplatesFromStoredQuestionnnaires(records, user);
	}

	async getQueuedQuestionnairesCount(portalKey: string, user: User | null): Promise<number> {
		let recordsCount: number;
		if (user) {
			recordsCount = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Uploading, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Uploading, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.count();
		} else {
			recordsCount = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Completed, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.Uploading, ""])
				.or("[status.questionnaireState+userId]")
				.equals([QuestionnaireProgressState.UploadFailed, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.count();
		}
		return recordsCount;
	}

	async deletePortal(customerKey: string, portalKey: string): Promise<number> {
		return localData.portals.where({ key: portalKey, customerKey }).delete();
	}

	getDexieName(): string {
		return localData.name;
	}

	async deleteQuestionnaire(questionnaireId: string): Promise<void> {
		return localData
			.transaction("rw", localData.questionnaires, localData.attachments, async () => {
				await localData.attachments.where("recordId").equals(questionnaireId).delete();
				await localData.questionnaires
					.where({ "questionnaire.id": questionnaireId })
					.delete();
			})
			.then(() => {
				window.dispatchEvent(new Event("uploadRefresh"));
			});
	}

	async deleteTemplate(templateId: string) {
		return localData.questionnaireTemplates
			.where({ "questionnaire.templateId": parseInt(templateId) })
			.delete();
	}

	async getTemplateById(templateId: number): Promise<QuestionnaireTemplate | undefined> {
		return localData.questionnaireTemplates
			.where({ "questionnaire.templateId": parseInt(templateId.toString()) })
			.first();
	}

	async getPortalByKey(portalKey: string, customerKey: string): Promise<Portal | undefined> {
		const lowerCasePortalKey = portalKey.toLowerCase();
		const lowerCaseCustomerKey = customerKey.toLowerCase();

		return transformPortalForView(
			await localData.portals
				.where({
					key: lowerCasePortalKey,
					customerKey: lowerCaseCustomerKey,
				})
				.first(),
		);
	}

	async savePortal(portal: Portal): Promise<string | void> {
		return transformPortalForStorage(portal)
			.then((portalForStorage) => localData.portals.put(portalForStorage))
			.catch((e) => {
				this.handleError(e, false);
			});
	}

	async getLanguageByPortalKeyAndCustomerKey(portalKey: string, customerKey: string) {
		const portalLanguage = await localData.languages.get({
			customerKey: customerKey.toLowerCase(),
			key: portalKey.toLowerCase(),
		});
		return portalLanguage?.language;
	}

	async saveOrgUnits(customerKey: string, orgUnits: OrgUnit[]): Promise<string | void> {
		return localData.orgUnits.put({ customerKey, orgUnits }).catch((e) => {
			this.handleError(e, false);
		});
	}

	async saveQuestionnaire(
		questionnaire: QuestionnaireTemplate,
		user: User | null,
	): Promise<string | void> {
		if (
			user &&
			store.getState().portal.portals[0].authenticationMode !==
				PortalAuthenticationMode.Public
		) {
			const key = getEncryptionKey(user, QuestionnaireEncryptionVersion.V2);
			const encryptedQuestionnaire = encrypt(
				{ ...questionnaire, userId: user.profile.sub },
				key as Uint8Array,
			);
			const questionnaireToStore: EncryptedQuestionnaireTemplate = {
				questionnaire: questionnaire.questionnaire,
				status: questionnaire.status,
				userId: user.profile.sub || "",
				encryptedQuestionnaire,
				encryptionVersion: QuestionnaireEncryptionVersion.V2,
			};
			return localData.questionnaires.put(questionnaireToStore).catch((e) => {
				this.handleError(e, false);
			});
		}
		questionnaire.userId = "";
		return localData.questionnaires.put(questionnaire).catch((e) => {
			this.handleError(e, false);
		});
	}

	async getQuestionnaireByState(
		portalKey: string,
		questionnaireState: QuestionnaireProgressState,
		user: User | null,
	): Promise<QuestionnaireTemplate[]> {
		let records: StoredQuestionnaireTemplate[];
		if (user) {
			records = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([questionnaireState, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([questionnaireState, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.toArray();
		} else {
			records = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([questionnaireState, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.toArray();
		}
		return this.questionnaireTemplatesFromStoredQuestionnnaires(records, user);
	}

	async getQuestionnaireByStateCount(
		portalKey: string,
		questionnaireState: QuestionnaireProgressState,
		user: User | null,
	) {
		let recordsCount: number;
		if (user) {
			recordsCount = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([questionnaireState, user.profile.sub])
				.or("[status.questionnaireState+userId]")
				.equals([questionnaireState, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.count();
		} else {
			recordsCount = await localData.questionnaires
				.where("[status.questionnaireState+userId]")
				.equals([questionnaireState, ""])
				.and((q) => q.questionnaire.portalKey.toLowerCase() === portalKey.toLowerCase())
				.count();
		}
		return recordsCount;
	}

	async getQuestionnaireById(
		id: string,
		user: User | null,
	): Promise<QuestionnaireTemplate | undefined> {
		const storedQuestionnaire = await localData.questionnaires
			.where({ "questionnaire.id": id })
			.first();
		const questionnaire =
			storedQuestionnaire &&
			this.questionnaireTemplateFromStoredQuestionnnaire(storedQuestionnaire, user);

		if (questionnaire && isQuestionnaireUpgradeAvailable(questionnaire)) {
			const upgradedQuestionnaire = upgradeQuestionnaire(questionnaire);
			await this.saveQuestionnaire(upgradedQuestionnaire, user);
			return upgradedQuestionnaire;
		}

		return questionnaire;
	}

	async saveTemplate(template: QuestionnaireTemplate): Promise<string | void> {
		return localData.questionnaireTemplates.put(template);
	}

	async getOrgUnitsByCustomerKey(customerKey: string): Promise<OrgUnit[]> {
		const orgs = await localData.orgUnits
			.where("customerKey")
			.equalsIgnoreCase(customerKey)
			.first();

		if (!orgs?.orgUnits) {
			return [];
		}

		return orgs.orgUnits;
	}

	async deleteOrgUnitsByCustomerKey(customerKey: string): Promise<void> {
		return localData.orgUnits.delete(customerKey);
	}

	async addAttachments(attachments: Attachment[], user: User | null): Promise<string | void> {
		let convertedAttachments;
		const userForStorage =
			store.getState().portal.portals[0].authenticationMode ===
			PortalAuthenticationMode.Public
				? null
				: user;
		try {
			convertedAttachments = await convertBlobFilesToArrayBufferType(
				attachments,
				userForStorage,
			);
		} catch (e) {
			logException(e);
		}
		return localData.attachments.bulkPut(convertedAttachments || attachments).catch((e) => {
			this.handleError(e, true);
		});
	}

	removeAttachment(attachmentId: string): Promise<number> {
		return localData.attachments.where("id").equals(attachmentId).delete();
	}

	removeRecordAttachments(recordId: string): Promise<number> {
		return localData.attachments.where("recordId").equals(recordId).delete();
	}

	async loadRecordAttachments(recordId: string, user: User | null): Promise<Attachment[]> {
		const storedAttachments = (await localData.attachments
			.where("recordId")
			.equals(recordId)
			.toArray()) as StoredAttachment[];

		const attachments: Attachment[] = storedAttachments.reduce(
			(acc: Attachment[], storedAttachment) => {
				try {
					let file = storedAttachment.file;
					if (storedAttachment.userId && user && file instanceof ArrayBuffer) {
						file = decryptArrayBuffer(
							storedAttachment.file,
							getEncryptionKey(
								user,
								storedAttachment.encryptionVersion,
							) as Uint8Array,
						);
					}
					const blob = new Blob([file]);
					const attachment = {
						...storedAttachment,
						file: blob,
					} as Attachment;
					acc.push(attachment);
				} catch (e) {
					logException(e);
				}
				return acc;
			},
			[],
		);

		return attachments;
	}

	async loadAttachment(attachmentId: string, user: User | null): Promise<Attachment> {
		const storedAttachment = (await localData.attachments
			.where("id")
			.equals(attachmentId)
			.first()) as StoredAttachment;

		let file = storedAttachment.file;
		if (storedAttachment.userId && user && file instanceof ArrayBuffer) {
			file = decryptArrayBuffer(
				storedAttachment.file,
				getEncryptionKey(user, storedAttachment.encryptionVersion) as Uint8Array,
			);
		}
		const blob = new Blob([file]);
		const attachment = {
			...storedAttachment,
			file: blob,
		} as Attachment;
		return attachment;
	}

	handleError(e: Error, isAttachmentAction: boolean) {
		if (
			e.message &&
			typeof e.message === "string" &&
			e.message.includes("QuotaExceededError")
		) {
			if (isAttachmentAction) {
				store.dispatch({
					type: CommonActionTypes.CommonError,
					error: "attachmentQuotaError",
				} as CommonErrorAction);
			} else {
				store.dispatch({
					type: CommonActionTypes.CommonError,
					error: "otherQuotaError",
				} as CommonErrorAction);
			}
		}
		logException({ error: e });
	}

	private questionnaireTemplatesFromStoredQuestionnnaires(
		questionnaires: StoredQuestionnaireTemplate[],
		user: User | null,
	) {
		return questionnaires.reduce<QuestionnaireTemplate[]>((acc, questionnaires) => {
			try {
				acc.push(this.questionnaireTemplateFromStoredQuestionnnaire(questionnaires, user));
			} catch (error) {
				logException({
					error,
					customData: {
						user: user?.profile,
					},
				});
			}
			return acc;
		}, []);
	}

	private questionnaireTemplateFromStoredQuestionnnaire(
		questionnaire: StoredQuestionnaireTemplate,
		user: User | null,
	) {
		if (questionnaire && isEncryptedQuestionnaireTemplate(questionnaire) && user) {
			const decryptedQuestionnnare = decrypt(
				questionnaire.encryptedQuestionnaire,
				getEncryptionKey(user, questionnaire.encryptionVersion) as Uint8Array,
			);
			return decryptedQuestionnnare as QuestionnaireTemplate;
		}
		return questionnaire as QuestionnaireTemplate;
	}
}
